import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const mailchimpFormRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.jQuery) {
        window.fnames = new Array();
        window.ftypes = new Array();
        window.fnames[0]='EMAIL';
        window.ftypes[0]='email';
        window.fnames[1]='FNAME';
        window.ftypes[1]='text';
        window.fnames[2]='LNAME';
        window.ftypes[2]='text';
        window.fnames[3]='ADDRESS';
        window.ftypes[3]='address';
        window.fnames[4]='PHONE';
        window.ftypes[4]='phone';
        window.fnames[5]='BIRTHDAY';
        window.ftypes[5]='birthday';
        window.jQuery(mailchimpFormRef.current).validate();
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-container">
      <h1>
        Welcome to VendorScout Dashboard{' '}
        <span role="img" aria-label="construction">🚧</span>
      </h1>
      <p>Thank you for signing up, {user.email}! We're thrilled to have you on board.</p>
      
      <div className="construction-message">
        <p>
          <span role="img" aria-label="hammer and wrench">🛠️</span> Our team is working hard to build amazing features for you!
        </p>
        <p>
          In the meantime, why not join our mailing list to stay updated on our progress? 
          <span role="img" aria-label="rocket">🚀</span>
        </p>
      </div>

      <div id="mc_embed_signup">
        <form 
          action="https://vendorscout.us21.list-manage.com/subscribe/post?u=0401be36d4d6d01746846f1fc&amp;id=501a13290f&amp;f_id=005687e6f0" 
          method="post" 
          id="mc-embedded-subscribe-form" 
          name="mc-embedded-subscribe-form" 
          className="validate" 
          target="_blank" 
          ref={mailchimpFormRef}
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <h2>Subscribe to Our Newsletter</h2>
            <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
            </div>
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
              <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
            </div>
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text" name="b_0401be36d4d6d01746846f1fc_501a13290f" tabIndex="-1" value="" />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="fun-facts">
        <h2>Did you know? <span role="img" aria-label="thinking face">🤔</span></h2>
        <ul>
          <li>The average company uses over 100 SaaS applications!</li>
          <li>Businesses can save up to 30% on software costs with proper vendor management.</li>
          <li>VendorScout is here to make your software procurement a breeze! <span role="img" aria-label="wind">💨</span></li>
        </ul>
      </div>

      <div className="support-message">
        <p>Need help or have questions? Our support team is just a click away!</p>
        <a 
          href="mailto:support@vendorscout.net" 
          className="support-button"
        >
          Contact Support <span role="img" aria-label="speech bubble">💬</span>
        </a>
      </div>
    </div>
  );
};

export default Dashboard;