import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ArrowRight, Mail, LogIn, Menu } from 'lucide-react';
import './LandingPage.css';

const LandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="landing-page">
      <header className={isScrolled ? 'scrolled' : ''}>
        <nav>
          <div className="logo">VendorScout</div>
          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            <Menu />
          </button>
          <ul className={isMobileMenuOpen ? 'mobile-menu-open' : ''}>
            <li><a href="#features">Features</a></li>
            <li><a href="#how-it-works">How It Works</a></li>
            <li><a href="#for-whom">Who It's For</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><Link to="/login" className="login-button"><LogIn size={18} /> Login</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <section className="hero">
          <h1>Streamline Your Software Vendor Selection</h1>
          <p>Access instant, crowdsourced pricing information for IT solutions.</p>
          <Link to="/register" className="cta-button">Get Started <ArrowRight size={18} /></Link>
          <div className="scroll-indicator">
            <span>Explore More</span>
            <ChevronDown size={24} />
          </div>
        </section>
        <section id="features">
          <h2>Key Features</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <div className="feature-icon">⚡</div>
              <h3>Instant Pricing Access</h3>
              <p>Get up-to-date pricing information with just a few clicks.</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">👥</div>
              <h3>Crowdsourced Data</h3>
              <p>Benefit from the collective knowledge of IT professionals.</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">⏱️</div>
              <h3>Time-Saving</h3>
              <p>Eliminate redundant vendor meetings and negotiations.</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">🔒</div>
              <h3>Anonymous Uploads</h3>
              <p>Securely upload vendor quotes without revealing your identity.</p>
            </div>
          </div>
        </section>
        <section id="how-it-works">
          <h2>How VendorScout Works</h2>
          <div className="steps-container">
            <div className="step">
              <div className="step-number">1</div>
              <p>Sign up for a VendorScout account</p>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <p>Search for the software solution you need</p>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <p>Access crowdsourced pricing information</p>
            </div>
            <div className="step">
              <div className="step-number">4</div>
              <p>Make informed decisions quickly</p>
            </div>
          </div>
          <div className="additional-info">
            <h3>Anonymous Quote Uploads</h3>
            <p>VendorScout allows users to anonymously upload quotes they've received from vendors. This process ensures that sensitive information remains confidential while contributing to our comprehensive database.</p>
            <h3>Manual Review Process</h3>
            <p>Every uploaded quote undergoes a thorough manual review by our expert team. This ensures the accuracy and reliability of the information in our database, providing you with trustworthy insights for your decision-making process.</p>
            <h3>Free!</h3>
            <p>VendorScout was created to help solve my frustrations with dealing with annoying salespeople and endless meetings. I don't want to put a price on your time. All I ask is that you upload any quotes you've received in return.</p>
          </div>
        </section>
        <section id="for-whom">
          <h2>Who VendorScout is For</h2>
          <div className="audience-grid">
            <div className="audience-item">
              <h3>IT Managers</h3>
              <p>Streamline your software procurement process and make informed decisions based on real market data.</p>
            </div>
            <div className="audience-item">
              <h3>Procurement Specialists</h3>
              <p>Gain leverage in negotiations with vendors by accessing comprehensive pricing information.</p>
            </div>
            <div className="audience-item">
              <h3>CTOs and CIOs</h3>
              <p>Align your technology investments with market trends and optimize your IT budget allocation.</p>
            </div>
            <div className="audience-item">
              <h3>Startups and SMBs</h3>
              <p>Level the playing field by accessing pricing information typically available only to larger enterprises.</p>
            </div>
          </div>
        </section>
        <section id="contact">
          <h2>Get In Touch</h2>
          <div className="contact-info">
            <a href="mailto:hello@vendorscout.net" className="email-link">
              <Mail size={24} />
              <span>hello@vendorscout.net</span>
            </a>
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-content">
          <div className="footer-logo">VendorScout</div>
          <div className="footer-links">
            <a href="#privacy">Privacy Policy</a>
            <a href="#terms">Terms of Service</a>
            <a href="#careers">Careers</a>
          </div>
          <div className="social-links">
            {/* Add social media icons here */}
          </div>
        </div>
        <p>&copy; 2024 VendorScout. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;