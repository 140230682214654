import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCB0wihCYou35187Bd_vpSj5q86uxsYgHA",
  authDomain: "vendorscout-58362.firebaseapp.com",
  projectId: "vendorscout-58362",
  storageBucket: "vendorscout-58362.appspot.com",
  messagingSenderId: "924128652013",
  appId: "1:924128652013:web:3213be380d7df882887ae4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
export default app;
